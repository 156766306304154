// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $.ajax({
        url: root + "UserAccount/GetCompanyName",
        type: 'GET',
        dataType: "text",
        success: function (companyName) {
            $("#myAccountHeaderText").text(companyName);
        }
    });
});
 $(document).on("scroll", function () {
    if ($(document).scrollTop() > 100) {
       $(".nav-link.w-nav-link, .nav-link.nav-dropdown.w-dropdown-toggle").addClass("shrink-nav-links");
        $(".social-icon-wrapper.social-top.st").addClass("shrink-social-media");
      $(".logo").addClass("shrink-logo");
      $(".nav-link.mid.w-nav-link").addClass("shrink");
    }
    else {
         $(".nav-link.w-nav-link, .nav-link.nav-dropdown.w-dropdown-toggle").removeClass("shrink-nav-links");
       $(".social-icon-wrapper.social-top.st").removeClass("shrink-social-media");
       $(".logo").removeClass("shrink-logo");
      $(".nav-link.mid.w-nav-link").removeClass("shrink");
    }
 });

$(function () {
    $(window).on('resize', SetNavDropdownMaxHeight);
    $(document).on('click', '.menu-button', SetNavDropdownMaxHeight);
    $(document).on('click', '.nav-link.nav-dropdown', SetNavDropdownMaxHeight);
    $(document).on('click', '.bag-opener.w-nav-link', SetNavDropdownMaxHeight);
    $('#bag-opener').click(function () {
        SetNavDropdownMaxHeight();
    });
});

function SetNavDropdownMaxHeight() {
    if ($(window).width() < 768) {
        $('.w-nav-overlay').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.mega-list').css('max-height', '100%');
    } else {
        $('.mega-list').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', '400px');
        $('.topheader').css('overflow', 'auto');
        $('.w-nav-overlay').css('max-height', '100%');
    }
}
